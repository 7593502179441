
import Vue from 'vue';
import { wizardProvider, TommyHelper } from '@/wizard';
import moment from 'moment';
import { TommyClient } from '../TommyClient';
import AvailabilityModel from './model/AvailabilityModel';
import AvailabilityAccommodationModel from './model/AvailabilityAccommodationModel';
import AccommodationWidgetResult from './AccommodationWidgetResult.vue';

function extractTime(input?: string): string {
  if (!input || '' === `${input}`) {
    return '';
  }

  const parsed = moment(input);

  return `${parsed.format('h:mm')}`;
}

export default Vue.extend({
  props: {
    widget: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
  },
  components: {
    AccommodationWidgetResult,
  },
  data() {
    return {
      page: 0,
      pageData: {
        show: {
          prev: false,
          next: false,
        },
        results: {
          default: [],
          alternative: [],
          all: [],
          page: [],
          gap: 0,
          gapPage: 0,
        },
      },
      wizardProvider: wizardProvider,
      helper: wizardProvider.helper as TommyHelper,
      ready: false,
      results: [] as any,
      alternativeResults: [] as any,
      values: {},
      client: {} as TommyClient,
      receipt: {} as AvailabilityModel,
    };
  },
  watch: {
    page: function(newPageValue: number) {
      const pageData: any = this.pageData;

      if (0 !== this.pageData.results.gapPage) {
        pageData.results.gapPage = this.pageData.results.gapPage;
        pageData.results.gap = this.pageData.results.gap;

        if (newPageValue <= pageData.results.gapPage) {
          pageData.results.gapPage = 0;
          pageData.results.gap = 0;
        }
      }

      const defaultResults: any = this.results;
      const alternativeResults: any = this.alternativeResults;

      pageData.results.page = [];

      const allResults: any = [];
      defaultResults.forEach((item: any) => {
        allResults.push({ item: item, default: true, divider: false });
      });
      alternativeResults.forEach((item: any) => {
        allResults.push({ item: item, default: false, divider: false });
      });

      // default results pagination:
      let sliceStart: number = 0;
      if (newPageValue > 1) {
        // prev button
        sliceStart += 8;
        pageData.show.prev = true;
      } else {
        pageData.show.prev = false;
      }
      if (newPageValue > 2) {
        // prev-next button
        sliceStart += (newPageValue - 2) * 7;
      }

      if (0 !== pageData.results.gapPage && newPageValue > pageData.results.gapPage) {
        sliceStart -= pageData.results.gap;
      }

      let sliceEnd: number = newPageValue === 1 ? sliceStart + 8 : sliceStart + 7;

      if (sliceEnd < allResults.length) {
        pageData.show.next = true;
      } else {
        pageData.show.next = false;
      }

      pageData.results.page = allResults.slice(sliceStart, sliceEnd);
      pageData.results.all = allResults;
      pageData.results.alternative = [];
      pageData.results.default = [];

      pageData.results.page.forEach((item: any) => {
        if (item.default) {
          pageData.results.default.push(item);
        } else {
          pageData.results.alternative.push(item);
        }
      });

      if (pageData.results.default.length > 0 && pageData.results.alternative.length > 0) {
        if (pageData.results.default.length < 3) {
          pageData.results.gap = pageData.results.alternative.length - 1;
        } else if (pageData.results.default.length < 6) {
          pageData.results.gap = pageData.results.alternative.length - 2;
        }

        pageData.results.gapPage = newPageValue;
        pageData.results.alternative = pageData.results.alternative.slice(
          0,
          pageData.results.alternative.length - pageData.results.gap,
        );
      }

      this.pageData = pageData;
    },
  },
  async mounted() {
    const meta: any = this.$store.getters.meta;
    const accommodations = this.$store.getters['apiAccommodations'];
    const locale: string = this.$store.getters.locale;
    const choices: any = this.$store.getters.choices;

    const receipt: AvailabilityModel = await this.helper.client.getPriceAndAvailability();
    accommodations.forEach((accommodation: any) => {
      const priceAvailabilityAccommodation = this.getAccommodationReceipt(receipt, accommodation);
      if (priceAvailabilityAccommodation) {
        this.results.push(this.createResult(receipt, priceAvailabilityAccommodation, accommodation));
      }
    });

    const alternativePayloads: number[] = [];

    const apiContext: any = this.$store.getters['apiContext'];
    const duration: any = apiContext.duration;
    if (duration > 2) {
      alternativePayloads.push(-2);
    }
    if (duration > 1) {
      alternativePayloads.push(-1);
    }
    alternativePayloads.push(1);
    alternativePayloads.push(2);

    if (meta.alternativeResults) {
      alternativePayloads.forEach(async (alternativePayload: number, index) => {
        const receipt: AvailabilityModel = await this.helper.client.getPriceAndAvailability(alternativePayload);
        accommodations.forEach((accommodation: any) => {
          const priceAvailabilityAccommodation = this.getAccommodationReceipt(receipt, accommodation);

          if (priceAvailabilityAccommodation) {
            this.alternativeResults.push(this.createResult(receipt, priceAvailabilityAccommodation, accommodation));

            // ready, update page.
            if (index === alternativePayloads.length - 1) {
              this.ready = true;
              this.page = 1;
            }
          }
        });
      });
    } else {
      this.ready = true;
      this.page = 1;
    }

    if (!this.$store.getters.didSkipAccommodation && 1 === this.results.length && 0 === this.alternativeResults.length) {
      this.$store.commit('SET_DID_SKIP_ACCOMMODATION', true);
      this.$emit('choose', { data: this.results[0] });
    }
  },
  methods: {
    triggerClick: function(result: any) {
      this.$emit('choose', { data: result });
    },
    createResult: function(
      receipt: AvailabilityModel,
      priceAvailabilityAccommodation: AvailabilityAccommodationModel,
      accommodation: any,
    ): any {
      const result: any = {
        code: accommodation.id,
        label: accommodation.vertalingen[this.$store.getters.tommyLocale],
        date: receipt.response.period.end,
        price: priceAvailabilityAccommodation.priceTotal(),
        duration: receipt.params.duration,
        imageUrl: accommodation.image || false,
        arrivalTime: extractTime(accommodation.aankomsttijd),
        departureTime: extractTime(accommodation.vertrektijd),
      };

      return result;
    },
    getAccommodationReceipt: function(receipt: AvailabilityModel, accommodation: any): AvailabilityAccommodationModel | null {
      const meta: any = this.$store.getters.meta;
      const apiContext = this.$store.getters['apiContext'];
      const accommodationGroups: any = apiContext.accommodationGroups || null;

      // filter by accommodation group when requested by wizard attribute.
      if (
        null !== accommodationGroups &&
        accommodationGroups.length > 0 &&
        !accommodationGroups.includes(accommodation.accommodatiegroep_id)
      ) {
        console.debug(
          'exclude "' + accommodation.naam + '" by group ids',
          '"' + accommodation.accommodatiegroep_id + '" not in (' + accommodationGroups.join(', ') + ')',
        );
        return;
      }

      return receipt.getAccommodation(accommodation.id);
    },
    getAccommodationLabel: function(accommodation: any): string {
      const labels: any = this.$store.getters.meta.accommodationLabels;
      if (!labels) {
        return undefined;
      }

      const accommodationId: number = parseInt(accommodation.code);

      let label: string = undefined;
      Object.keys(labels).forEach((tmpAccommodationId: any) => {
        if (parseInt('' + tmpAccommodationId) === accommodationId) {
          label = labels[tmpAccommodationId] || undefined;
        }
      });

      return undefined === label ? undefined : this.wizardProvider.translate(label);
    },
    getAccommodationDescription: function(accommodation: any, key: string = 'description'): string {
      const metadata: any = this.$store.getters.apiMetadata;
      if (!metadata) {
        return undefined;
      }

      const accommodationId: number = parseInt(accommodation.code);

      let descriptions: any = false;
      metadata.accommodations.forEach((metaAcco: any) => {
        if (parseInt(metaAcco.id) === accommodationId) {
          descriptions = metaAcco[key] || false;
        }
      });

      if (!descriptions) {
        return '';
      }

      return descriptions[this.$store.getters.tommyLocale] || descriptions[this.$store.getters.locale] || '';

      /*
      const accommodationId: number = parseInt(accommodation.code);

      console.warn(accommodationId, labels, Object.keys(labels));

      let label: string = undefined;
      Object.keys(labels).forEach((tmpAccommodationId: any) => {
        if (parseInt('' + tmpAccommodationId) === accommodationId) {
          label = labels[tmpAccommodationId] || undefined;
        }
      });

      return undefined === label ? undefined : this.wizardProvider.translate(label);
      */
    },
  },
});
