import baseConfig from './config-base';

const newConfig = JSON.parse(JSON.stringify(baseConfig));
newConfig.user = 'camperparkzeeland';
newConfig.steps[0].buttons.forEach((button: any) => {
  switch (button.code) {
    case 'nl':
      button.label = 'Boek een camperplaats';
      break;
    case 'de':
      button.label = 'Buchen Sie einen Wohnmobilstellplatz';
      break;
    case 'fr':
      button.label = 'Réservez un emplacement de camping-car';
      break;
    case 'en':
    case 'gb':
      button.label = 'Book a motor home pitch';
      break;
  }
});

export default newConfig;
