import baseConfig from './config-base';

const newConfig = JSON.parse(JSON.stringify(baseConfig));
newConfig.user = 'camperparkamsterdam';

// Add language select + two follow-up options to the flow
const newSteps = [
  {
    type: 'default',
    code: 'language-select',
    title: '',
    buttons: [
      {
        label: '',
        code: 'nl-default',
        slots: { top: '<div class="flag"><span>Nederlands</span><img src="/img/icons/nl.svg" /></div>' },
        options: {
          translate: false,
          classes: ['language'],
        },
      },
      {
        label: '',
        code: 'de-default',
        slots: { top: '<div class="flag"><span>Deutsch</span><img src="/img/icons/de.svg" /></div>' },
        options: {
          translate: false,
          classes: ['language'],
        },
      },
      {
        label: '',
        code: 'en-default',
        slots: { top: '<div class="flag"><span>English</span><img src="/img/icons/uk.svg" /></div>' },
        options: {
          translate: false,
          classes: ['language'],
        },
      },
      {
        label: '',
        code: 'fr-default',
        slots: { top: '<div class="flag"><span>Français</span><img src="/img/icons/fr.svg" /></div>' },
        options: {
          translate: false,
          classes: ['language'],
        },
      },
      // {
      //     label: '',
      //     code: 'se-default',
      //     slots: { top: '<div class="flag"><span>Svenska</span><img src="/img/icons/se.svg" /></div>' },
      //     options: {
      //         translate: false,
      //         classes: ['language'],
      //     },
      // },
      {
        label: '',
        code: 'es-default',
        slots: { top: '<div class="flag"><span>Espanol</span><img src="/img/icons/es.svg" /></div>' },
        options: {
          translate: false,
          classes: ['language'],
        },
      },
      {
        label: '',
        code: 'it-default',
        slots: { top: '<div class="flag"><span>Italiano</span><img src="/img/icons/it.svg" /></div>' },
        options: {
          translate: false,
          classes: ['language'],
        },
      },
      // {
      //     label: '',
      //     code: 'no-default',
      //     slots: { top: '<div class="flag"><span>Norsk</span><img src="/img/icons/no.svg" /></div>' },
      //     options: {
      //         translate: false,
      //         classes: ['language'],
      //     },
      // },
      // {
      //     label: '',
      //     code: 'pt-default',
      //     slots: { top: '<div class="flag"><span>Português</span><img src="/img/icons/pt.svg" /></div>' },
      //     options: {
      //         translate: false,
      //         classes: ['language'],
      //     },
      // },
    ],
  },
  {
    type: 'default',
    code: 'flow-select',
    title: '',
    buttons: [
      {
        label: 'flow-select-new-reservation',
        code: 'default',
        options: {
          translate: true,
          salesChannel: 20884,
        },
      },
      {
        label: 'flow-select-existing-reservation',
        code: '-bezoek',
        options: {
          translate: true,
          salesChannel: 20508,
        },
      },
    ],
  },
];

const extraIntroStep = {
  type: 'default',
  code: 'intro0',
  title: 'title-intro-0',
  buttons: [
    {
      label: 'btn-next',
      code: 'start-booking0',
    },
  ],
  layout: {
    rows: [
      {
        columns: [
          {
            type: 'title',
            classes: ['col-12'],
          },
        ],
      },
      {
        columns: [
          {
            type: 'text',
            classes: ['col-12'],
            data: 'intro-text-0',
          },
        ],
      },
      {
        columns: [
          {
            type: 'buttons',
            classes: ['col-12'],
          },
        ],
      },
    ],
  },
};

newConfig.steps.splice(1, 0, extraIntroStep);
newConfig.steps.splice(0, 1, ...newSteps);

export default newConfig;
