import baseConfig from './config-base';

const newConfig = JSON.parse(JSON.stringify(baseConfig));
newConfig.user = 'biesthoutakker';
newConfig.steps[0].buttons.forEach((button: any) => {
  switch (button.code) {
    case 'nl':
      button.label = 'Boek een camperplaats';
      break;
    case 'de':
      button.label = 'Buchen Sie einen Wohnmobilstellplatz';
      break;
    case 'en':
    case 'gb':
      button.label = 'Book a mobilhome pitch';
      break;
  }
});

export default newConfig;
