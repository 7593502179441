
import Vue from 'vue';
import { wizardProvider, TommyHelper } from '@/wizard';
import moment from 'moment';
import { TommyClient } from '../TommyClient';
import ReceiptModel from './model/ReceiptModel';
import { mapGetters } from 'vuex';

export default Vue.extend({
  props: {
    compact: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      wizardProvider: wizardProvider,
      ready: false,
      values: {},
      client: {} as TommyClient,
      receipt: {} as ReceiptModel,
    };
  },
  mounted() {
    const choices: any = this.$store.getters.choices;

    this.client = (this.$app.helper as any).client;
    this.client.getReceipt().then((receipt: ReceiptModel) => {
      this.receipt = receipt;
      this.ready = true;
    });
  },
  methods: {
    triggerClick: function() {
      this.$emit('choose', { data: false });
    },
    getApiMetaAccommodation(accommodationId: number): any {
      const metadata: any = this.$store.getters.apiMetadata;
      if (!metadata) {
        return undefined;
      }

      let accommodation: any = false;
      metadata.accommodations.forEach((metaAcco: any) => {
        if (parseInt(metaAcco.id) === accommodationId) {
          accommodation = metaAcco;
        }
      });

      if (false === accommodation) {
        return false;
      }

      return accommodation;
    },
    getArrivalTime(accommodationId: number) {
      const acco: any = this.getApiMetaAccommodation(accommodationId);

      return acco && acco.arrivalTime && acco.arrivalTime.date
        ? '' + wizardProvider.translate('after') + ' ' + Vue.filter('date_format')(acco.arrivalTime.date, { type: 'time' })
        : '';
    },
    getDepartureTime(accommodationId: number) {
      const acco: any = this.getApiMetaAccommodation(accommodationId);

      return acco && acco.departureTime && acco.departureTime.date
        ? '' + wizardProvider.translate('before') + ' ' + Vue.filter('date_format')(acco.departureTime.date, { type: 'time' })
        : '';
    },
  },
  computed: {
    personaldataChoice(): any {
      return this.$store.getters.choices.personaldata.data;
    },
    locationName(): string {
      const locale = this.$store.getters.locale;
      if (!this.choices.floorplan) {
        return '';
      }

      const locationChoice: any = this.choices.floorplan.data || {};
      if (locationChoice) {
        return (locationChoice.vertalingen || [])[locale] || locationChoice.naam;
      }

      return '';
    },
    ...mapGetters(['apiContext', 'choices', 'locale']),
  },
});
