
import Vue from 'vue';
import { wizardProvider, TommyHelper } from '@/wizard';
import { mapGetters } from 'vuex';
import ReceiptDetailsWidget from './ReceiptDetailsWidget.vue';

export default Vue.extend({
  components: {
    ReceiptDetailsWidget,
  },
  data() {
    return {
      wizardProvider: wizardProvider,
      ready: false,
      checkPaymentInterval: false as any,
      paymentIntervalAttempts: 0 as any,
      checkTransactionInterval: false as any,
      transactionIntervalAttempts: 0 as any,
      paid: false,
      failed: false,
      fails: 0,
      usePin: false,
      showRetry: false,
    };
  },
  destroyed() {
    this.clearTransaction();
    this.clearPayment();
  },
  mounted() {
    const meta: any = this.$store.getters.meta;
    const usePin: boolean = meta.usePin || false;
    this.usePin = usePin;

    const choices: any = this.$store.getters.choices;

    if (usePin) {
      const translationContext = this.$store.getters.translationContext;

      console.warn('translationContext', translationContext.reserveringsId, translationContext.reserveringsnummer);

      if (translationContext.reserveringsId && translationContext.reserveringsnummer) {
        this.checkTransaction();
      }
      this.ready = true;
    }
  },
  methods: {
    quit: function() {
      this.$store.dispatch('restoreConfig');
      this.$store.dispatch('updateMountKey');
      this.$store.commit('SET_STEP_NUMBER', 1);
    },
    clearTransaction() {
      this.transactionIntervalAttempts = 0;
      clearInterval(this.checkTransactionInterval);
    },
    clearPayment() {
      this.paymentIntervalAttempts = 0;
      clearInterval(this.checkPaymentInterval);
    },
    checkTransaction() {
      const translationContext = this.$store.getters.translationContext;
      const client = (this.$app.helper as any).client;
      this.checkTransactionInterval = setInterval(() => {
        ++this.transactionIntervalAttempts;
        client
          .checkTransaction(
            translationContext.reserveringsId,
            translationContext.reserveringsnummer,
            translationContext.transactionId,
          )
          .then((data: any) => {
            if (typeof data.data !== 'undefined') {
              if (false === data.data.pending) {
                this.clearTransaction();

                this.checkPaymentInterval = setInterval(() => {
                  ++this.paymentIntervalAttempts;

                  client
                    .checkPayment(translationContext.reserveringsId, translationContext.reserveringsnummer)
                    .then((data: any) => {
                      if (data.success && typeof data.data !== 'undefined' && true === (data.data.paid || false)) {
                        this.clearPayment();

                        this.paid = true;

                        if (this.meta.arrivalPrinter) {
                          return client.postSignupReservation(translationContext.reserveringsnummer, this.meta.arrivalPrinter);
                        }

                        return;
                      } else if (this.paymentIntervalAttempts >= 15) {
                        this.clearPayment();

                        this.fail();
                      }
                    });
                }, 2000);
              } else if (this.transactionIntervalAttempts >= 30) {
                this.clearTransaction();
                this.clearPayment();

                this.fail();
              }
            }
          });
      }, 2000);
    },
    retry() {
      const translationContext = this.$store.getters.translationContext;
      const client = (this.$app.helper as any).client;
      this.showRetry = false;

      client.pushTransaction(translationContext.reserveringsId, translationContext.reserveringsnummer).then((response: any) => {
        this.$store.commit('UPDATE_TRANSLATION_CONTEXT', {
          transactionId: response.data.transactionId,
        });

        this.checkTransaction();
      });
    },
    fail() {
      ++this.fails;

      this.showRetry = true;
      if (this.fails > 2) {
        this.clearTransaction();
        this.clearPayment();
        this.showRetry = false;
        this.failed = true;
      }
    },
  },
  computed: {
    ...mapGetters(['translationContext', 'meta']),
    totalAmount(): number {
      return this.translationContext['total_receipt_price'] || 0;
    },
  },
});
