
import Vue from 'vue';
import { wizardProvider } from '@/wizard';
import NumberToggle from '@/components/NumberToggle.vue';
import moment from 'moment';
import { TommyClient } from '../TommyClient';
import AvailabilityModel from './model/AvailabilityModel';
import { mapGetters } from 'vuex';
import debounce from 'lodash/debounce';
import AvailabilityAccommodationModel from './model/AvailabilityAccommodationModel';

export default Vue.extend({
  components: {
    NumberToggle,
  },
  data() {
    return {
      wizardProvider: wizardProvider,
      client: {} as TommyClient,
      values: {
        duration: 1,
        start: '',
        end: '',
      } as any,
      names: [] as string[],
      ready: false,
      resultCount: '...' as any,
      loading: true,
    };
  },
  mounted() {
    this.client = (this.$app.helper as any).client;
    this.updateDuration(this.values.duration);
  },
  methods: {
    triggerClick: function() {
      this.values['start'] = Vue.filter('date_format')(this.arrival, { type: 'date' });
      this.values['start_full'] = Vue.filter('date_format')(this.arrival, { type: 'date-full' });
      this.values['end'] = Vue.filter('date_format')(this.departure, { type: 'date' });
      this.values['end_full'] = Vue.filter('date_format')(this.departure, { type: 'date-full' });

      this.$emit('choose', { data: this.values });
    },
    updateDuration: debounce(async function(this: any, value: number) {
      this.loading = true;
      this.$store.dispatch('startLoader');
      this.resultCount = '...';

      if (this.isPerDay) {
        this.$store.commit('SET_TOMMY_DURATION', value - 1);
      } else {
        this.$store.commit('SET_TOMMY_DURATION', value);
      }

      this.client = this.$app.helper.client;

      const apiAccommodations = this.$store.getters.apiAccommodations;
      const getAccommodation: any = (id: any) => {
        let res: any = false;
        apiAccommodations.forEach((apiAccommodation: any) => {
          if (parseInt(apiAccommodation.id) === parseInt(id)) {
            res = apiAccommodation;
          }
        });

        return res;
      };

      const locale = this.$store.getters.tommyLocale;

      this.names = [];
      await this.client.getPriceAndAvailability().then((receipt: AvailabilityModel) => {
        this.resultCount = 0;
        receipt.accommodations.forEach((accommodationResponse: AvailabilityAccommodationModel) => {
          const acco: any = getAccommodation(accommodationResponse.accommodationId());
          const accoName = (acco.vertalingen || [])[locale] || acco.naam;

          if (acco && !this.names.includes(accoName)) {
            this.names.push(accoName);
          }

          // todo: filter accommodations by accommodation group.
          this.resultCount++;
        });
      });

      this.names.sort();

      this.ready = true;
      this.$store.dispatch('stopLoader');
      this.loading = false;
    }, 500),
  },
  computed: {
    arrival(): any {
      return moment(this.startDate);
    },
    departure(): any {
      return moment(this.startDate).add(this.$store.getters.apiContext.duration, 'days');
    },
    maxDuration(): any {
      return this.meta.maxDuration || undefined;
    },
    isPerDay(): boolean {
      return this.$store.getters.apiContext.perDay === true;
    },
    ...mapGetters(['startDate', 'meta']),
  },
});
