import { appService } from '@/service/AppService';
import { TommyClient } from './TommyClient';
import Vue from 'vue';
import moment from 'moment';

// not yet initialized, but calling this "factory" within store method we know it is already initialized.
const getClient = (): TommyClient => {
  return (appService.helper as any).client;
};

export const state = () => {
  return {
    apiContext: {
      language: 'nl',
      salesChannel: null as any,
      accommodationGroups: null as any,
      travelGroup: {} as any,
      duration: null as number,
      startdate: null as any,
      enddate: null as any,
      accommodation: null as any,
      extras: null as any,
      couponCode: null as any,
      location: null as any,
      priceTrigger: null as any,
      perDay: false,
      disableFloorplan: false,
    } as any,
    apiMetadata: null as any,
    apiArticles: null as any,
    apiCharacteristics: null as any,
    apiFloorplan: null as any,
    apiPersonDetails: null as any,
    apiAccommodations: null as any,
    apiAccommodationGroups: null as any,
    apiWidgetboeken: null as any,
    apiPersonCategories: null as any,
    apiLanguages: null as any,
    apiSettings: null as any,
    apiTravelGroupLimits: null as any,
    petsConfig: null as any,
    extrasConfig: null as any,
    petsCount: 0 as number,
    tommyLocale: null as any,
    startDate: null as any,
    timeoutVisible: false as boolean,
    didSkipAccommodation: false as boolean,
    timeTableEnabled: false as boolean,
    timeTableStatus: 0 as number,
  };
};

export const mutations = {
  SET_TOMMY_LOCALE(state: any, tommyLocale: any) {
    state.tommyLocale = tommyLocale;
  },
  SET_DID_SKIP_ACCOMMODATION(state: any, skipped: boolean) {
    state.didSkipAccommodation = skipped;
  },
  SET_START_DATE(state: any, startDateString: any) {
    state.startDate = startDateString;
  },
  SET_TOMMY_SALESCHANNEL(state: any, salesChannel: any) {
    Vue.set(state.apiContext, 'salesChannel', salesChannel);
  },
  SET_TOMMY_PER_DAY(state: any, perDay: boolean) {
    Vue.set(state.apiContext, 'perDay', perDay);
  },
  SET_TOMMY_DISABLE_FLOORPLAN(state: any, disableFloorplan: boolean) {
    Vue.set(state.apiContext, 'disableFloorplan', disableFloorplan);
  },
  SET_TOMMY_LANGUAGE(state: any, language: any) {
    Vue.set(state.apiContext, 'language', language);
  },
  SET_TOMMY_PRICE_TRIGGER(state: any, priceTrigger: any) {
    Vue.set(state.apiContext, 'priceTrigger', priceTrigger);
  },
  SET_TOMMY_DURATION(state: any, duration: number) {
    Vue.set(state.apiContext, 'duration', duration);

    const startdate: any = moment(state.startDate);
    const enddate = moment(state.startDate).add(duration, 'days');
    Vue.set(state.apiContext, 'startdate', startdate.format('YYYY-MM-DD'));
    Vue.set(state.apiContext, 'enddate', enddate.format('YYYY-MM-DD'));
  },
  SET_TOMMY_ACCOMMODATIONGROUPS(state: any, accommodationGroups: any) {
    Vue.set(state.apiContext, 'accommodationGroups', accommodationGroups);
  },
  SET_TOMMY_ACCOMMODATION(state: any, accommodation: any) {
    Vue.set(state.apiContext, 'accommodation', accommodation);
  },
  SET_TOMMY_LOCATION(state: any, location: any) {
    Vue.set(state.apiContext, 'location', location);
  },
  SET_TOMMY_EXTRAS(state: any, extras: any) {
    Vue.set(state.apiContext, 'extras', extras);
  },
  SET_TOMMY_TRAVELGROUP(state: any, travelGroup: any) {
    Vue.set(state.apiContext, 'travelGroup', travelGroup);
  },
  SET_TOMMY_COUPON_CODE(state: any, couponCode: any) {
    Vue.set(state.apiContext, 'couponCode', couponCode);
  },
  SET_TOMMY_API_META(state: any, apiData: any) {
    state.apiMetadata = apiData;
  },
  SET_TOMMY_API_WIDGET_BOEKEN(state: any, apiData: any) {
    state.apiWidgetboeken = apiData;
  },
  SET_TOMMY_API_TRAVELGROUP_LIMITS(state: any, travelGroupLimits: any) {
    state.apiTravelGroupLimits = travelGroupLimits;
  },
  SET_TOMMY_API_ARTICLES(state: any, articles: any) {
    state.apiArticles = articles;
  },
  SET_TOMMY_API_CHARACTERISTICS(state: any, characteristics: any) {
    state.apiCharacteristics = characteristics;
  },
  SET_TOMMY_API_FLOORPLAN(state: any, floorplan: any) {
    state.apiFloorplan = floorplan;
  },
  SET_TOMMY_API_PERSONDETAILS(state: any, personDetails: any) {
    state.apiPersonDetails = personDetails;
  },
  SET_TOMMY_API_ACCOMMODATIONS(state: any, accommodations: any) {
    const result: any = [];
    accommodations.forEach((accommodation: any) => {
      // create "image" attribute on each accommodation. Use the first valid image found in different accommodation objects and store it.
      accommodation.image = '';

      state.apiMetadata.accommodations.forEach((metaAccommodation: any) => {
        // filter accommodation by id.
        if ('' + accommodation.id === '' + metaAccommodation.id && metaAccommodation.images.length > 0) {
          metaAccommodation.images.forEach((image: any) => {
            if ('' === accommodation.image) {
              accommodation.image = image.image;
            }
          });
        }
      });

      // loop through media objects on accommodation object itself.
      if ('' === accommodation.image && accommodation.media.length > 0) {
        accommodation.media.forEach((image: any) => {
          if ('' === accommodation.image && true === image.active) {
            accommodation.image = '' + image.path + image.filename;
          }
        });
      }

      result.push(accommodation);
    });

    state.apiAccommodations = result;
  },
  SET_TOMMY_API_ACCOMMODATIONGROUPS(state: any, accommodationGroups: any) {
    state.apiAccommodationGroups = accommodationGroups;
  },
  SET_TOMMY_API_PERSONCATEGORIES(state: any, personCategories: any) {
    state.apiPersonCategories = personCategories;
  },
  SET_TOMMY_API_LANGUAGES(state: any, languages: any) {
    state.apiLanguages = languages;
  },
  SET_TOMMY_API_SETTINGS(state: any, settings: any) {
    // already saved in seperate store entry.
    delete settings.plattegrond;
    state.apiSettings = settings;
  },
  SET_TIMEOUT_VISIBLE(state: any, visibility: boolean) {
    state.timeoutVisible = visibility;
  },
  SET_PETS_CONFIG(state: any, petsConfig: any) {
    state.petsConfig = petsConfig;
  },
  SET_EXTRAS_CONFIG(state: any, extrasConfig: any) {
    state.extrasConfig = extrasConfig;
  },
  SET_PETS_COUNT(state: any, petsCount: number) {
    state.petsCount = petsCount;
  },
  SET_TIMETABLE_ENABLED(state: any, enabled: boolean) {
    state.timeTableEnabled = enabled;
  },
  SET_TIMETABLE_STATUS(state: any, status: number) {
    state.timeTableStatus = status;
  },
};

export const getters = {
  apiContext: (state: any) => {
    return state.apiContext;
  },
  salesChannel: (state: any) => {
    return state.apiContext.salesChannel;
  },
  language: (state: any) => {
    return state.apiContext.language;
  },
  priceTrigger: (state: any) => {
    return state.apiContext.priceTrigger;
  },
  accommodationGroups: (state: any) => {
    return state.apiContext.accommodationGroups;
  },
  duration: (state: any) => {
    return state.apiContext.duration;
  },
  apiMetadata: (state: any) => {
    return state.apiMetadata;
  },
  apiWidgetboeken: (state: any) => {
    return state.apiWidgetboeken;
  },
  apiArticles: (state: any) => {
    return state.apiArticles;
  },
  apiCharacteristics: (state: any) => {
    const disabledExtrasConfig: any = (state.extrasConfig || {}).disabled || [];

    return state.apiCharacteristics.filter((characteristic: any) => {
      return !disabledExtrasConfig.includes(parseInt(characteristic.id));
    });
  },
  apiAccommodations: (state: any) => {
    return state.apiAccommodations;
  },
  apiAccommodationGroups: (state: any) => {
    return state.apiAccommodationGroups;
  },
  apiPersonCategories: (state: any) => {
    return state.apiPersonCategories;
  },
  apiLanguages: (state: any) => {
    return state.apiLanguages;
  },
  apiSettings: (state: any) => {
    return state.apiSettings;
  },
  apiFloorplan: (state: any) => {
    return state.apiFloorplan;
  },
  apiPersonDetails: (state: any) => {
    return state.apiPersonDetails;
  },
  apiTravelGroupLimits: (state: any) => {
    return state.apiTravelGroupLimits;
  },
  tommyLocale: (state: any) => {
    return state.tommyLocale;
  },
  startDate: (state: any) => {
    return state.startDate;
  },
  timeoutVisible: (state: any) => {
    return state.timeoutVisible;
  },
  petsConfig: (state: any) => {
    return state.petsConfig;
  },
  extrasConfig: (state: any) => {
    return state.extrasConfig;
  },
  petsCount: (state: any) => {
    return state.petsCount;
  },
  articlesShown: (state: any) => {
    const petIds: any = (state.petsConfig.articles || []).map((val: any) => parseInt(val));

    const list = (state.apiArticles || []).filter((article: any) => {
      return !petIds.includes(parseInt(article.id)) && article.automatisch !== true;
    });

    list.sort((a: any, b: any) => {
      return a.volgorde - b.volgorde;
    });

    return list;
  },
  didSkipAccommodation: (state: any) => {
    return state.didSkipAccommodation;
  },
  timeTableEnabled: (state: any) => {
    return state.timeTableEnabled;
  },
  timeTableStatus: (state: any) => {
    return state.timeTableStatus;
  },
};

export const actions = {
  updateLocale(context: any, payload: any) {
    moment.locale(payload);
    context.commit('SET_LOCALE', payload);
    context.commit('SET_TOMMY_LOCALE', Vue.filter('map_tommy_locale')(payload));
  },
  async updateTommyAccommodations(context: any, payload: any) {
    context.commit('SET_TOMMY_API_ACCOMMODATIONS', (await getClient().getAccommodations()).data.data);
  },
  async updateTommyArticles(context: any, payload: any) {
    context.commit('SET_TOMMY_API_ARTICLES', (await getClient().getArticles()).data.data);
  },
  async updateTommyCharacteristics(context: any, payload: any) {
    context.commit('SET_TOMMY_API_CHARACTERISTICS', (await getClient().getCharacteristics()).data.data);
  },
  showTimeout(context: any, payload: any) {
    context.commit('SET_TIMEOUT_VISIBLE', true);
  },
  setTimetableEnabled(context: any, payload: any) {
    context.commit('SET_TIMETABLE_ENABLED', payload);
  },
  setTimetableStatus(context: any, payload: any) {
    context.commit('SET_TIMETABLE_STATUS', payload);
  },
};

export const tommyStoreModule: any = {
  state,
  mutations,
  actions,
  getters,
};
